export default [
  // { 原三方商旅页面
  //   // 协议公司列表
  //   path: 'agreementCompany',
  //   name: 'agreementCompany',
  //   meta: {
  //     permissionCode: 'cp-hotel-agreementCustomerManage',
  //     routerType: 2,
  //   },
  //   component: () => import('../views/group-management/agreement-company/index.vue'),
  // },
  {
    // 协议公司信息
    path: 'agreementCompany/info/:type?/:companyId?/:unitId?',
    name: 'agreementCompanyInfo',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/agreement-company/agreement-company-edit/index.vue'),
  },
  {
    // 预设房型
    path: 'agreementCompany/defaultRoomTypes',
    name: 'defaultRoomTypes',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/agreement-company/default-room-types/index.vue'),
  },
  {
    // 预设合同价
    path: 'agreementCompany/defaultContractRate',
    name: 'defaultContractRate',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/agreement-company/default-contract-rate/index.vue'),
  },
  {
    // 客户联系人列表
    path: 'customerManagement/:companyId?/:companyName?',
    name: 'customerManagement',
    meta: {
      permissionCode: 'cp-customerContactManagement',
      routerType: 2,
    },
    component: () => import('../views/group-management/customer-management/index.vue'),
  },
  {
    // 联系人导入列表
    path: 'customerImport',
    name: 'customerImport',
    meta: {
      permissionCode: 'cp-customerContactManagement',
      routerType: 2,
    },
    component: () => import('../views/group-management/customer-import/index.vue'),
  },
  {
    // 员工白名单
    path: 'customerWhiteList',
    name: 'customerWhiteList',
    meta: {
      permissionCode: 'cp-customerContactManagement',
      routerType: 2,
    },
    component: () => import('../views/group-management/customer-white-list/index.vue'),
  },
  {
    // 合同列表
    path: 'agreementCompany/contract/list/:companyId/:companyName',
    name: 'contractList',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/contract-management/contract-list/index.vue'),
  },
  {
    // 合同基本信息
    path: 'agreementCompany/contract/info/:companyId/:companyName/:contractId?',
    name: 'contractBaseInfo',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/contract-management/contract-edit/index.vue'),
  },
  {
    // 合同-协议价
    path: 'agreementCompany/contract/price/:companyId/:companyName/:contractId',
    name: 'contractPriceInfo',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/contract-management/agreement-price/index.vue'),
  },
  {
    // 酒店投放房型
    path: 'agreementCompany/contract/roomtype/:companyId/:companyName/:contractId',
    name: 'contractRoomtype',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/contract-management/put-roomtype/index.vue'),
  },
  {
    // 酒店协议公司信息
    path: 'hotelAgreementCompany/info/:type?/:companyId?/:unitId?',
    name: 'hotelAgreementCompanyInfo',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/agreement-company/agreement-company-edit/index.vue'),
  },
  {
    // 共享酒店协议单位设置
    path: 'shareHotelAgreement',
    name: 'shareHotelAgreement',
    meta: {
      permissionCode: 'cp-group-shareHotelAgreement',
      routerType: 2,
    },
    component: () => import('../views/group-management/share-hotel-agreement/index.vue'),
  },
  {
    // 酒店共享协议公司
    path: 'hotelAgreementCompany',
    name: 'hotelAgreementCompany',
    meta: {
      permissionCode: 'cp-group-shareHotelAgreement',
      routerType: 2,
    },
    component: () => import('../views/group-management/hotel-agreement-company/index.vue'),
  },
  {
    // 酒店协议公司信息
    path: 'hotelAgreementCompanyInfoShare/info/:type?/:companyId?/:unitId?',
    name: 'hotelAgreementCompanyInfoShare',
    meta: {
      permissionCode: 'cp-group-shareHotelAgreement',
      routerType: 2,
    },
    component: () => import('../views/group-management/agreement-company/agreement-company-edit/index.vue'),
  },
  {
    // 集团直签公司与三方商旅合并
    path: 'agreementCompany',
    name: 'agreementCompany',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/group-management/group-sign-company/index.vue'),
  },
  {
    // 直签协议公司信息
    path: 'agreementCompany/groupSignCompany/info/:type?/:companyId?/:unitId?',
    name: 'groupSignCompanyInfo',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/agreement-company/agreement-company-edit/index.vue'),
  },
  {
    // 合作酒店
    path: 'agreementCompany/setCooperateHotel/:companyId?/:unitId?',
    name: 'setCooperateHotel',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/agreement-company/set-cooperate-hotel/index.vue'),
  },
  {
    // 酒店下发日志
    path: 'operateHotelLog',
    name: 'operateHotelLog',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/agreement-company/operate-hotel-log/index.vue'),
  },
  {
    // 合同价格策略编辑页
    path: 'agreementCompany/groupSignCompany/contractRateInfo/:companyId/:companyName/:contractId?',
    name: 'contractRateInfo',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/contract-rate-management/index.vue'),
  },
  {
    // 设置合同有效房型
    path: 'groupSignCompany/contractValidRoomtype/:contractId?/:contractType?/:companyName?',
    name: 'contractValidRoomtype',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/group-sign-company/contract-valid-roomtype/index.vue'),
  },
  {
    // 设置合同折扣模板
    path: 'companyDiscountTemplate',
    name: 'companyDiscountTemplate',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 2,
      deepth: 2,
    },
    component: () => import('../views/group-management/group-sign-company/company-discount-template/index.vue'),
  }, {
    // 发票线索池
    path: 'invoiceClueIndex',
    name: 'invoiceClueIndex',
    meta: {
      permissionCode: 'cp-groupCluePool',
      routerType: 2,
    },
    component: () => import('../views/group-management/invoice-clue-pool/index.vue'),
  }, {
    // 发票线索池
    path: 'invoiceClueEdit',
    name: 'invoiceClueEdit',
    meta: {
      permissionCode: 'cp-groupCluePool',
      routerType: 2,
    },
    component: () => import('../views/group-management/invoice-clue-pool/invoice-edit/index.vue'),
  },{
    // 发票线索池
    path: 'invoiceClueHistory',
    name: 'invoiceClueHistory',
    meta: {
      permissionCode: 'cp-groupCluePool',
      routerType: 2,
    },
    component: () => import('../views/group-management/invoice-clue-pool/history.vue'),
  },
];
